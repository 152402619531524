import { PUB_SUB_EVENTS } from '../helper/constants';
import { fetchConfig } from '../helper/fetch-config';
import { publish } from '../helper/pubsub';

// Product Form

if (!customElements.get('product-form')) {
  customElements.define(
    'product-form',
    class ProductForm extends HTMLElement {
      constructor() {
        super();

        this.form = this.querySelector('form');
        this.form.querySelector('[name=id]').disabled = false;
        this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
        this.cart = document.querySelector('cart-drawer');
        this.submitButton = this.querySelector('[type="submit"]');

        this.hideErrors = this.dataset.hideErrors === 'true';

        this.promo_message = '';
        this.promos = document.querySelectorAll('.js-promotext');
        this.productTags = this.querySelectorAll('.product-tag');
        this.tag_array = [];
        this.promoElem = this.querySelector('.promo-text');
      }

      connectedCallback() {
        this.productTags.forEach((elem) => {
          const tagValue = elem.dataset.tag;
          if (tagValue) {
            this.tag_array.push(tagValue);
          }
        });

        this.promos.forEach((elem) => {
          if (this.tag_array.includes(elem.dataset.tag)) {
            if (elem.dataset.tag != '3 for $48 panties' && window.location.href.indexOf('products') != -1) {
              this.promoElem.textContent = elem.textContent;
              this.promoElem.style.color = `${elem.dataset.color}`;
            }
          }
        });
      }

      onSubmitHandler(evt) {
        evt.preventDefault();
        if (this.submitButton.getAttribute('aria-disabled') === 'true') return;

        if (this.submitButton.getAttribute('select-size') === 'true') {
          if (this.sizeSelect) {
            //check if there is a size selected.
            const radios = this.sizeSelect.querySelectorAll('input[type="radio"]');
            let hasValue = false;
            radios.forEach((element) => {
              if (element.checked) hasValue = true;
            });

            if (hasValue) {
              this.message.textContent = '';

              this.submitButton.setAttribute('select-size', 'false');

              if (this.stickyATC) {
                this.stickyATC.removeAttribute('scoll');
              }
            } else {
              console.log('hello');
              this.message.textContent = window.variantStrings.selectVariant;

              this.submitButton.setAttribute('select-size', 'true');

              if (this.stickyATC) {
                this.stickyATC.setAttribute('scroll', 'true');
              }
            }
          }
          return;
        }
        // TODO -- VARIANT SIZE SELECT BEFORE ATC
        // check if there is a selected value in the size & there is more than 1 size option.
        // if there is not change the ATC text / error text to be Select A size.
        // if there is a selected value & more than 1 size option add to cart.

        this.handleErrorMessage();

        this.submitButton.setAttribute('aria-disabled', true);
        this.submitButton.classList.add('loading');

        const config = fetchConfig('javascript');
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        delete config.headers['Content-Type'];

        const formData = new FormData(this.form);
        if (this.cart) {
          formData.append(
            'sections',
            this.cart.getSectionsToRender().map((section) => section.id)
          );
          formData.append('sections_url', window.location.pathname);
          this.cart.setActiveElement(document.activeElement);
        }
        config.body = formData;

        fetch(`${routes.cart_add_url}`, config)
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              publish(PUB_SUB_EVENTS.cartError, {
                source: 'product-form',
                productVariantId: formData.get('id'),
                errors: response.description,
                message: response.message
              });
              this.handleErrorMessage(response.description);

              const soldOutMessage = this.submitButton.querySelector('.sold-out-message');
              if (!soldOutMessage) return;
              this.submitButton.setAttribute('aria-disabled', true);
              this.submitButton.querySelector('span').classList.add('hidden');
              soldOutMessage.classList.remove('hidden');
              this.error = true;
              return;
            } else if (!this.cart) {
              window.location = window.routes.cart_url;
              return;
            }

            if (!this.error)
              publish(PUB_SUB_EVENTS.cartUpdate, { source: 'product-form', productVariantId: formData.get('id') });
            this.error = false;
            console.log(response);
            this.cart.trigger(this.cart);
            this.cart.renderContents(response);
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.submitButton.classList.remove('loading');
            if (this.cart && this.cart.classList.contains('is-empty')) this.cart.classList.remove('is-empty');
            if (!this.error) this.submitButton.removeAttribute('aria-disabled');
          });
      }

      handleErrorMessage(errorMessage = false) {
        if (this.hideErrors) return;

        this.errorMessageWrapper =
          this.errorMessageWrapper || this.querySelector('.product-form__error-message-wrapper');
        if (!this.errorMessageWrapper) return;
        this.errorMessage = this.errorMessage || this.errorMessageWrapper.querySelector('.product-form__error-message');

        this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);

        if (errorMessage) {
          this.errorMessage.textContent = errorMessage;
        }
      }
    }
  );
}
